<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="page-title">Deliveries</div>
            <div class="deliveries__filters">
              <transition name="fade">
                <div v-show="filterShow" class="deliveries__filters-inner">
                  <div></div>
                  <div
                    class="field__label field__label--status"
                    :class="{
                      'field__label--status--green': filterParams.status && filterParams.status.value === 1,
                      'field__label--status--grey': filterParams.status && filterParams.status.value === null,
                      'field__label--status--red': filterParams.status && filterParams.status.value === 0
                    }"
                  >
                    <Multiselect
                      v-model="filterParams.status"
                      style="width: 95px"
                      :options="statuses"
                      :placeholder="'Status'"
                      class="select-status select--small select-status--desctop"
                      :class="{
                        'border-red': filterParams.status && filterParams.status.value === 0,
                        'border-green': filterParams.status && filterParams.status.value === 1,
                        'border-grey': filterParams.status && filterParams.status.value === null
                      }"
                      track-by="value"
                      label="label"
                      :show-labels="false"
                      :allow-empty="false"
                      :searchable="false"
                      @select="filterStatus"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <div
                          class="option__desc"
                          :class="{
                            'option__desc--green': props.option.value === 1,
                            'option__desc--grey': props.option.value === 2,
                            'option__desc--red': props.option.value === 0
                          }"
                        >
                          <div
                            class="round"
                            :class="{
                              'round--green': props.option.value === 1,
                              'round--grey': props.option.value === 2,
                              'round--red': props.option.value === 0
                            }"
                          />
                          <span class="option__title">{{ props.option.label }}</span>
                        </div>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div
                          class="option__desc"
                          :class="{
                            'option__desc--green': props.option.value === 1,
                            'option__desc--grey': props.option.value === 2,
                            'option__desc--red': props.option.value === 0
                          }"
                        >
                          <div
                            class="round"
                            :class="{
                              'round--green': props.option.value === 1,
                              'round--grey': props.option.value === 2,
                              'round--red': props.option.value === 0
                            }"
                          />
                          <span class="option__title">{{ props.option.label }}</span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                  <div class="field__label field__label--dropdown">
                    <Multiselect
                      v-model="filterParams.date"
                      style="width: 257px"
                      :disabled="filterParams.dateRange ? true : false"
                      :options="dates"
                      :placeholder="'Recent'"
                      class="select-recent select--small"
                      track-by="value"
                      label="label"
                      multiple
                      :show-labels="false"
                      :searchable="false"
                      @input="filterDate"
                    >
                    </Multiselect>
                  </div>
                  <date-picker
                    v-model="filterParams.dateRange"
                    range
                    style="width: 210px"
                    :disabled="filterParams.date && filterParams.date.length ? true : false"
                    format="DD.MM.YYYY"
                    value-type="format"
                    @change="filterRangeDate"
                  ></date-picker>
                  <div class="field__label--search">
                    <i class="ri-search-2-line"></i>
                    <input
                      id="searchDelivery"
                      class="field__input"
                      :value="searchParams"
                      placeholder="Search deliveries"
                      @input="searchDelivery"
                    />
                  </div>
                </div>
              </transition>
            </div>
            <div class="users__button-wrapper">
              <div class="field__label--search is-mobile">
                <i class="ri-search-2-line"></i>
                <input
                  class="field__input"
                  :value="searchParams"
                  placeholder="Search deliveries"
                  @input="searchDelivery"
                />
              </div>
              <button
                class="button--iconFilter"
                :class="{'button--iconFilter--dis': filterShow}"
                size="small"
                @click="showFilter"
              >
                <transition name="fade">
                  <i v-if="filterShow" key="close" class="ri-close-circle-fill"></i>
                  <div v-else-if="filterCounter && !filterShow" class="button--iconFilter-counter">
                    {{ filterCounter }}
                  </div>
                  <i v-else key="open" class="ri-filter-fill"></i>
                </transition>
              </button>
              <button class="button button--fill" @click="showAddDelivery">
                <i class="ri-add-circle-fill" />
                <span>Add New Delivery</span>
              </button>
              <button
                class="icon-button icon-button--round icon-button--mobile icon-button--blue button-fly"
                @click="showAddDeliveryMobile"
              >
                <i class="ri-add-circle-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <Delivery
          v-for="delivery in deliveries"
          :key="delivery.id"
          :info="delivery"
          @click.native="showDelivery(delivery)"
        />
      </div>
    </div>
    <el-drawer
      class="el--mobile"
      :visible.sync="filterShowMobile"
      direction="btt"
      :show-close="false"
      :with-header="false"
      destroy-on-close
      size="40"
    >
      <div class="filters__header">
        <span>Filters</span>
        <button class="icon-button" @click="filterShowMobile = false">
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div class="filters__body">
        <div class="filters__body-part-top">
          <div
            class="field__label field__label--status"
            :class="{
              'field__label--status--green': filterParams.status && filterParams.status.value === 1,
              'field__label--status--grey': filterParams.status && filterParams.status.value === null,
              'field__label--status--red': filterParams.status && filterParams.status.value === 0
            }"
          >
            <Multiselect
              v-model="filterParams.status"
              :options="statuses"
              :placeholder="'Status'"
              class="select-status"
              track-by="value"
              label="label"
              :show-labels="false"
              :allow-empty="false"
              :searchable="false"
              @select="filterStatus"
            >
              <template slot="singleLabel" slot-scope="props">
                <div
                  class="option__desc"
                  :class="{
                    'option__desc--green': props.option.value === 1,
                    'option__desc--grey': props.option.value === 2,
                    'option__desc--red': props.option.value === 0
                  }"
                >
                  <div
                    class="round"
                    :class="{
                      'round--green': props.option.value === 1,
                      'round--grey': props.option.value === 2,
                      'round--red': props.option.value === 0
                    }"
                  />
                  <span class="option__title">{{ props.option.label }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div
                  class="option__desc"
                  :class="{
                    'option__desc--green': props.option.value === 1,
                    'option__desc--grey': props.option.value === 2,
                    'option__desc--red': props.option.value === 0
                  }"
                >
                  <div
                    class="round"
                    :class="{
                      'round--green': props.option.value === 1,
                      'round--grey': props.option.value === 2,
                      'round--red': props.option.value === 0
                    }"
                  />
                  <span class="option__title">{{ props.option.label }}</span>
                </div>
              </template>
            </Multiselect>
          </div>
          <div class="field__label field__label--dropdown">
            <Multiselect
              v-model="filterParams.date"
              :disabled="filterParams.range[0] || filterParams.range[1] ? true : false"
              :options="dates"
              :placeholder="'Recent'"
              class="select-recent"
              track-by="value"
              label="label"
              multiple
              :show-labels="false"
              :searchable="false"
              @input="filterDate"
            >
            </Multiselect>
          </div>
        </div>
        <div class="filters__body-part-title">Date Range</div>
        <div class="filters__body-part-middle">
          <input
            v-model="filterParams.range[0]"
            class="field__date--mobile"
            type="date"
            :disabled="filterParams.date && filterParams.date.length ? true : false"
            @input="filterRangeDate"
          />
          <input
            v-model="filterParams.range[1]"
            class="field__date--mobile"
            type="date"
            :disabled="filterParams.date && filterParams.date.length ? true : false"
            @input="filterRangeDate"
          />
        </div>
      </div>
    </el-drawer>
    <el-drawer
      class="el--mobile"
      :visible.sync="deliveryShow"
      direction="btt"
      :show-close="false"
      :with-header="false"
      destroy-on-close
      size="64%"
    >
      <DeliveryInfo :info="activeDelivery" />
    </el-drawer>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Delivery from './Delivery'
import DeliveryInfo from './DeliveryPopupMobile'
import InfiniteLoading from 'vue-infinite-loading'
import _ from 'lodash'
import Popup from './DeliveryPopup'
import DatePicker from 'vue2-datepicker'
import Multiselect from 'vue-multiselect'
import moment from 'moment'

export default {
  name: 'Deliveries',
  components: {Delivery, DeliveryInfo, InfiniteLoading, DatePicker, Multiselect},
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      activeDelivery: {},
      searchParams: '',
      filterParams: {},
      filterShow: false,
      filterShowMobile: false,
      deliveryShow: false,
      statuses: [
        {
          value: 2,
          label: 'Show All'
        },
        {
          value: 1,
          label: 'Done'
        },
        {
          value: 0,
          label: 'Not Done'
        }
      ],
      dates: [
        {
          value: 'today',
          label: 'Today'
        },
        {
          value: 'tomorrow',
          label: 'Tomorrow'
        },
        {
          value: 'thisWeek',
          label: 'This Week'
        }
      ]
    }
  },
  computed: {
    deliveries() {
      return this.$store.getters['deliveries/deliveries']
    },
    hasMoreResults() {
      return this.deliveries.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['deliveries/pagination'].total
    },
    page() {
      return this.$store.getters['deliveries/page']
    },
    filterCounter() {
      let counter = 0
      !_.isEmpty(this.filterParams.dateRange) ? counter++ : null
      !_.isEmpty(this.filterParams.date) ? counter++ : null
      this.filterParams.range[0] || this.filterParams.range[1] ? counter++ : null
      this.filterParams.status || this.filterParams.status === 0 ? counter++ : null
      this.filterParams.name ? counter++ : null
      return counter
    }
  },
  // watch: {
  // searchParams(param) {
  //   let oldParam = this.$store.getters['deliveries/filterParamsLocal'].name
  //   if (oldParam !== param) {
  //     this.filterParams.name = param
  //     this.getDelivery(this, param)
  //   }
  // }
  // },
  created() {
    if (!this.$store.getters['deliveries/pagination'].total) {
      this.$store.dispatch('deliveries/fetch')
    }
    if (this.$store.getters['deliveries/filterParamsLocal']) {
      this.filterParams = _.cloneDeep(this.$store.getters['deliveries/filterParamsLocal'])
    } else {
      this.filterParams = _.cloneDeep(this.$store.getters['deliveries/filterParamsOriginal'])
    }
    this.filterParams.name ? (this.searchParams = this.filterParams.name) : null
  },
  mounted() {
    this._keyListener = function(e) {
      if (e.key === 'f' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.focus()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
  },
  methods: {
    showAddDelivery() {
      this.$root.$emit('ShowSidebar', 'AddDelivery')
    },
    showAddDeliveryMobile() {
      this.$root.$emit('ShowSidebar', 'AddDeliveryMobile')
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('deliveries/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    searchDelivery(e) {
      const param = e.target.value
      this.searchParams = param

      let oldParam = this.$store.getters['deliveries/filterParamsLocal'].name
      if (oldParam !== param) {
        this.filterParams.name = param
        this.getDelivery(this, param)
      }
    },
    getDelivery: _.debounce((v, data) => {
      data === '' ? (data = null) : null
      const filtersLocal = v.$store.getters['deliveries/filterParamsLocal']
      filtersLocal.name = data
      v.$store.commit('deliveries/filterParamsLocal', filtersLocal)
      v.$store.commit('deliveries/searchParams', data)
      v.$store.commit('deliveries/reset')
      v.$store.dispatch('deliveries/fetch')
    }, 500),
    filterStatus(status) {
      status.value === 2 ? (status.value = null) : null
      const filters = this.$store.getters['deliveries/filterParamsSend']
      const filtersLocal = this.$store.getters['deliveries/filterParamsLocal']
      filters['filters[status]'] = status.value
      filtersLocal.status = status
      this.$store.commit('deliveries/filterParamsLocal', filtersLocal)
      this.$store.commit('deliveries/filterParamsSend', filters)
      this.$store.commit('deliveries/reset')
      this.$store.dispatch('deliveries/fetch')
    },
    filterDate(date) {
      const filters = this.$store.getters['deliveries/filterParamsSend']
      const filtersLocal = this.$store.getters['deliveries/filterParamsLocal']
      filters['filters[date]'] = date.map(d => d.value)
      filtersLocal.date = date.map(d => d.value)
      this.$store.commit('deliveries/filterParamsSend', filters)
      this.$store.commit('deliveries/filterParamsLocal', filtersLocal)
      this.$store.commit('deliveries/reset')
      this.$store.dispatch('deliveries/fetch')
    },
    filterRangeDate(date) {
      const d1 = this.filterParams.range[0]
      const d2 = this.filterParams.range[1]
      let d1local = null
      let d2local = null
      if (d1) d1local = d1.slice(8, 10) + '-' + d1.slice(5, 7) + '-' + d1.slice(0, 4)
      if (d2) d2local = d2.slice(8, 10) + '-' + d2.slice(5, 7) + '-' + d2.slice(0, 4)

      const mobile = document.documentElement.clientWidth <= 768
      if (!date[0]) {
        date = null
        this.filterParams.dateRange = null
      }
      const filters = this.$store.getters['deliveries/filterParamsSend']
      const filtersLocal = this.$store.getters['deliveries/filterParamsLocal']
      if (mobile) {
        filters['filters[dateRange][from]'] = d1local
        filters['filters[dateRange][till]'] = d2local
      } else {
        filters['filters[dateRange][from]'] = date ? date[0] : null
        filters['filters[dateRange][till]'] = date ? date[1] : null
      }

      filtersLocal.dateRange = date
      this.$store.commit('deliveries/filterParamsLocal', filtersLocal)
      this.$store.commit('deliveries/filterParamsSend', filters)
      this.$store.commit('deliveries/reset')
      this.$store.dispatch('deliveries/fetch')
    },
    resetFilter() {
      if (this.filterCounter) {
        this.filterParams = _.cloneDeep(this.$store.getters['deliveries/filterParamsOriginal'])

        this.$store.commit('deliveries/filterParamsLocal', null)
        this.$store.commit('deliveries/filterParamsSend', {})
        this.$store.commit('deliveries/reset')
        this.$store.dispatch('deliveries/fetch')
      }
    },
    focus() {
      document.querySelector('#searchDelivery').focus()
    },
    showDelivery(info) {
      const style = this.$modal.styles
      const mobile = document.documentElement.clientWidth <= 768
      if (mobile) {
        this.activeDelivery = info
        this.deliveryShow = !this.deliveryShow
      } else {
        this.$modal.show(
          Popup,
          {info},
          {...style, name: 'DeliveryPopup', height: 'auto'},
          {
            'before-open': this.$modal.open,
            'before-close': this.$modal.close
          }
        )
      }
    },
    showFilter() {
      const mobile = document.documentElement.clientWidth <= 768
      mobile ? (this.filterShowMobile = !this.filterShowMobile) : (this.filterShow = !this.filterShow)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
