<template>
  <div class="modal__inner">
    <div class="modal__title">
      <div class="round" :class="{'round--green': info.status, 'round--red': !info.status}"></div>
      <div class="modal__title-text">Delivery # {{ info.id }}</div>
      <div class="indicator">
        <div class="bg" :style="{'background-color': info.serviceColor}"></div>
        <span>{{ info.serviceName }}</span>
      </div>
    </div>
    <div class="modal__main">
      <div class="modal__row">
        <div class="icon-button icon-button--round icon-button--blue">
          <i class="ri-arrow-up-circle-line"></i>
        </div>
        <div class="modal__cell modal__cell-customer">
          <span v-if="info.pickUpFathersName">{{ info.pickUpFathersName }}’ </span>
          <span>{{ info.pickUpFirstName }} </span>
          <span>{{ info.pickUpMiddleInitial }} </span>
          <span>{{ info.pickUpLastName }} </span>
          <span v-if="info.pickUpNickName">({{ info.pickUpNickName }})</span>
          <span>{{ info.pickUpName }} </span>
        </div>
        <div class="modal__cell modal__cell-address">Address: {{ info.pickUpAddress }} {{ info.pickUpCity }}</div>
      </div>
      <div class="modal__row">
        <div class="icon-button icon-button--round icon-button--orange">
          <i class="ri-arrow-down-circle-line"></i>
        </div>
        <div class="modal__cell modal__cell-customer">
          <span v-if="info.billingFathersName">{{ info.billingFathersName }}’ </span>
          <span>{{ info.billingFirstName }} </span>
          <span>{{ info.billingMiddleInitial }} </span>
          <span>{{ info.billingLastName }} </span>
          <span v-if="info.billingNickName">({{ info.billingNickName }})</span>
          <span>{{ info.billingName }}</span>
        </div>
        <div class="modal__cell modal__cell-address">Address: {{ info.billingAddress }} {{ info.billingCity }}</div>
      </div>
      <div class="modal__row">
        <div class="icon-button icon-button--round icon-button--blue">
          <i class="ri-money-dollar-circle-line"></i>
        </div>
        <div class="modal__cell modal__cell-customer">
          <span v-if="info.dropOffFathersName">{{ info.dropOffFathersName }}’ </span>
          <span>{{ info.dropOffFirstName }} </span>
          <span>{{ info.dropOffMiddleInitial }} </span>
          <span>{{ info.dropOffLastName }} </span>
          <span v-if="info.dropOffNickName">({{ info.dropOffNickName }})</span>
          <span>{{ info.dropOffName }}</span>
        </div>
        <div class="modal__cell modal__cell-address">Address: {{ info.dropOffAddress }} {{ info.dropOffCity }}</div>
      </div>
    </div>
    <div v-if="info.notes || info.attachmentPath" class="modal__attachment">
      <div class="modal__cell">Attachments</div>
      <div v-if="info.notes" class="modal__notes">{{ info.notes }}</div>
      <div v-if="info.attachmentPath" class="field__label--image">
        <el-image :src="info.attachmentPath" :preview-src-list="[info.attachmentPath]" fit="cover" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeliveryPopup',
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  methods: {
    async changeStatus() {
      const status = +!this.info.status
      const sendData = {
        serviceID: this.info.serviceID,
        billing: this.info.billing,
        status,
        customers: this.info.customers.map(cus => {
          const customer = {customerID: cus.customerID, type: cus.type}
          cus.notes ? (customer.notes = cus.notes) : null
          return customer
        })
      }
      try {
        sendData.id = this.info.id
        await this.$store.dispatch('deliveries/update', sendData)
        this.$modal.hide('DeliveryPopup')
      } catch (err) {
        if (err.response.data.error) {
          alert(err.response.data.error)
        } else {
          alert(err.response.data.errors[0].error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
