var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table__row" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "table__row-inner" }, [
        _c("div", { staticClass: "table__cell table__cell--status" }, [
          _c("div", {
            staticClass: "round",
            class: {
              "round--green": _vm.info.status,
              "round--red": !_vm.info.status
            }
          })
        ]),
        _c("div", { staticClass: "table__cel table__cell--datel" }, [
          _c("div", { staticClass: "table__cell-title" }, [
            _vm._v(
              _vm._s(_vm.moment.utc(_vm.info.routeDate).format("D MMM YYYY"))
            )
          ])
        ]),
        _c("div", { staticClass: "table__cell table__cell-indicator" }, [
          _c("div", { staticClass: "indicator" }, [
            _c("div", {
              staticClass: "bg",
              style: { "background-color": _vm.info.serviceColor }
            }),
            _c("span", [_vm._v(_vm._s(_vm.$dots(_vm.info.serviceName, 40)))])
          ])
        ]),
        _c(
          "div",
          {
            staticClass:
              "table__cell table__cell--horizontal table__cell--customer"
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.$dots(_vm.info.pickUpName, 60)))
            ]),
            _c("div", { staticClass: "table__cell-subtitle" }, [
              _vm._v(_vm._s(_vm.$dots(_vm.info.pickUpAddress, 60)))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "table__cell table__cell--horizontal table__cell--customer"
          },
          [
            _vm._m(1),
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.$dots(_vm.info.dropOffName, 60)))
            ]),
            _c("div", { staticClass: "table__cell-subtitle" }, [
              _vm._v(_vm._s(_vm.$dots(_vm.info.dropOffAddress, 60)))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "table__cell table__cell--end table__cell--createdDate"
          },
          [
            _c("div", { staticClass: "table__cell-subtitle" }, [
              _vm._v(_vm._s(_vm.moment.utc(_vm.info.createdAt).format("D MMM")))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "table__cell table__cell--end table__cell--actions ",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(
              "el-dropdown",
              { attrs: { trigger: "click" }, on: { command: _vm.action } },
              [
                _c("i", { staticClass: "ri-more-fill" }),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "el-popper-custom el-popper--actions",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown"
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      { staticClass: "item--edit", attrs: { command: "Edit" } },
                      [
                        _c("i", { staticClass: "ri-pencil-fill" }),
                        _vm._v(" Edit ")
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "item--remove",
                        attrs: { command: "Archive" }
                      },
                      [
                        _c("i", { staticClass: "ri-delete-bin-7-fill" }),
                        _vm._v(" Archive ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table__row-card card" }, [
        _c("div", { staticClass: "card__header" }, [
          _c("div", { staticClass: "card__box" }, [
            _c("div", {
              staticClass: "round",
              class: {
                "round--green": _vm.info.status,
                "round--red": !_vm.info.status
              }
            })
          ]),
          _c("div", { staticClass: "card__box card__date" }, [
            _c("div", {}, [
              _vm._v(
                _vm._s(_vm.moment.utc(_vm.info.routeDate).format("D MMM YYYY"))
              )
            ])
          ]),
          _c("div", { staticClass: "card__box" }, [
            _c("div", { staticClass: "indicator" }, [
              _c("div", {
                staticClass: "bg",
                style: { "background-color": _vm.info.serviceColor }
              }),
              _c("span", [_vm._v(_vm._s(_vm.$dots(_vm.info.serviceName, 18)))])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "card__box",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "Edit" }
                        },
                        [
                          _c("i", { staticClass: "ri-pencil-fill" }),
                          _vm._v(" Edit ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--remove",
                          attrs: { command: "Archive" }
                        },
                        [
                          _c("i", { staticClass: "ri-delete-bin-7-fill" }),
                          _vm._v(" Archive ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "card__body" }, [
          _c("div", { staticClass: "card__line" }, [
            _vm._m(2),
            _c("div", { staticClass: "card__box" }, [
              _c("div", { staticClass: "card__title" }, [
                _vm._v(_vm._s(_vm.$dots(_vm.info.pickUpName, 40)))
              ]),
              _c("div", { staticClass: "card__subtitle" }, [
                _vm._v(_vm._s(_vm.info.pickUpAddress))
              ])
            ])
          ]),
          _c("div", { staticClass: "card__line" }, [
            _vm._m(3),
            _c("div", { staticClass: "card__box" }, [
              _c("div", { staticClass: "card__title" }, [
                _vm._v(_vm._s(_vm.$dots(_vm.info.dropOffName, 40)))
              ]),
              _c("div", { staticClass: "card__subtitle" }, [
                _vm._v(_vm._s(_vm.info.dropOffAddress))
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--blue" },
      [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--orange" },
      [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "icon-button icon-button--round icon-button--blue" },
      [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "icon-button icon-button--round icon-button--orange" },
      [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }