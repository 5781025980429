<template>
  <div class="table__row">
    <div class="container">
      <div class="table__row-inner">
        <div class="table__cell table__cell--status">
          <div class="round" :class="{'round--green': info.status, 'round--red': !info.status}"></div>
        </div>
        <div class="table__cel table__cell--datel">
          <div class="table__cell-title">{{ moment.utc(info.routeDate).format('D MMM YYYY') }}</div>
        </div>
        <div class="table__cell table__cell-indicator">
          <div class="indicator">
            <div class="bg" :style="{'background-color': info.serviceColor}"></div>
            <span>{{ $dots(info.serviceName, 40) }}</span>
          </div>
        </div>
        <div class="table__cell table__cell--horizontal table__cell--customer">
          <div class="icon-button icon-button--round icon-button--blue">
            <i class="ri-arrow-up-circle-line"></i>
          </div>
          <div class="table__cell-title">{{ $dots(info.pickUpName, 60) }}</div>
          <div class="table__cell-subtitle">{{ $dots(info.pickUpAddress, 60) }}</div>
        </div>
        <div class="table__cell table__cell--horizontal table__cell--customer">
          <div class="icon-button icon-button--round icon-button--orange">
            <i class="ri-arrow-down-circle-line"></i>
          </div>
          <div class="table__cell-title">{{ $dots(info.dropOffName, 60) }}</div>
          <div class="table__cell-subtitle">{{ $dots(info.dropOffAddress, 60) }}</div>
        </div>
        <div class="table__cell table__cell--end table__cell--createdDate">
          <div class="table__cell-subtitle">{{ moment.utc(info.createdAt).format('D MMM') }}</div>
        </div>
        <div class="table__cell table__cell--end table__cell--actions " @click.stop>
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <el-dropdown-item class="item--edit" command="Edit">
                <i class="ri-pencil-fill"></i> Edit
              </el-dropdown-item>
              <el-dropdown-item class="item--remove" command="Archive">
                <i class="ri-delete-bin-7-fill"></i> Archive
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <div class="round" :class="{'round--green': info.status, 'round--red': !info.status}"></div>
          </div>
          <div class="card__box card__date">
            <div class="">{{ moment.utc(info.routeDate).format('D MMM YYYY') }}</div>
          </div>
          <div class="card__box">
            <div class="indicator">
              <div class="bg" :style="{'background-color': info.serviceColor}"></div>
              <span>{{ $dots(info.serviceName, 18) }}</span>
            </div>
          </div>
          <div class="card__box" @click.stop>
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit" command="Edit">
                  <i class="ri-pencil-fill"></i> Edit
                </el-dropdown-item>
                <el-dropdown-item class="item--remove" command="Archive">
                  <i class="ri-delete-bin-7-fill"></i> Archive
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="card__body">
          <div class="card__line">
            <button class="icon-button icon-button--round icon-button--blue">
              <i class="ri-arrow-up-circle-line" />
            </button>
            <div class="card__box">
              <div class="card__title">{{ $dots(info.pickUpName, 40) }}</div>
              <div class="card__subtitle">{{ info.pickUpAddress }}</div>
            </div>
          </div>
          <div class="card__line">
            <button class="icon-button icon-button--round icon-button--orange">
              <i class="ri-arrow-down-circle-line" />
            </button>
            <div class="card__box">
              <div class="card__title">{{ $dots(info.dropOffName, 40) }}</div>
              <div class="card__subtitle">{{ info.dropOffAddress }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown'
import moment from 'moment'

export default {
  name: 'Delivery',
  components: {Dropdown},
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    value: 'dropdown',
    moment
  }),
  methods: {
    async action(act) {
      const mobile = document.documentElement.clientWidth <= 480
      let sidebarName = 'AddDelivery'
      mobile ? (sidebarName = 'AddDeliveryMobile') : (sidebarName = 'AddDelivery')
      if (act === 'Edit') {
        this.$root.$emit('ShowSidebar', {
          sidebarName,
          componentData: this.info
        })
      } else if (act === 'Archive') {
        try {
          await this.$store.dispatch('deliveries/archive', this.info.id)
        } catch (e) {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
